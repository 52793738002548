import { defineStore } from 'pinia'
import { ref } from 'vue'
import DiaryModel from '@/models/DiaryModel'
import TableSettings from '@/types/tableSettings'
import { notification, message } from 'ant-design-vue'
import { WrappedResponse } from 'vue-api-query'
import { useRouter } from 'vue-router'
import { Diary, DiaryDay, DiaryRegistration } from '@/types/diary'
import axios from 'axios'

export const diaryStore = defineStore('diary', () => {
  const router = useRouter()
  const loading = ref({ list: false, item: false, saving: false, deleting: 0, clearing: false, closing: false, generating: false })
  const errors = ref<{[key: string]: string[]}>({})
  const diaries = ref<WrappedResponse<DiaryModel[]>|DiaryModel[]>({
    data: [],
    meta: {
      per_page: 25,
      total: 0
    }
  })

  const diary = ref<Diary|null>(null)

  function getDiaries (tableSettings:TableSettings) : void {
    loading.value.list = true
    let diaryModel = new DiaryModel()

    // Add filters if there are any filters set.
    if (tableSettings.activeFilters) {
      Object.keys(tableSettings.activeFilters).forEach(key => {
        if (tableSettings.activeFilters[key].length) {
          diaryModel = diaryModel.whereIn(key, tableSettings.activeFilters[key])
        }
      })
    }

    // Check if only archived surveys have to be shown.
    if (tableSettings.archived) {
      diaryModel = diaryModel.where('status', 0)
    } else {
      diaryModel = diaryModel.where('status', 1)
    }

    // Add search if available.
    if (tableSettings.search) {
      diaryModel = diaryModel.where('search', tableSettings.search)
    }

    // Add orderBy if sort is set.
    if (tableSettings.sort && tableSettings.sort.order && tableSettings.sort.columnKey) {
      diaryModel = diaryModel.orderBy(tableSettings.sort.order === 'ascend' ? tableSettings.sort.columnKey : '-' + tableSettings.sort.columnKey)
    }

    diaryModel = diaryModel.limit(tableSettings.pagination.pageSize).page(tableSettings.pagination.current)

    diaryModel.get().then((r) => {
      diaries.value = r
    }).catch(() => {
      notification.error({ message: 'Fout tijdens het ophalen van het uitvoerdersdagboek!', description: 'Er is iets mis gegaan. Probeer het later nog een keer.', duration: 3 })
    }).finally(() => {
      loading.value.list = false
    })
  }

  function getDiary (id:string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      loading.value.item = true
      const diaryModel = new DiaryModel()

      diaryModel.find(id).then((r:any) => {
        diary.value = r.data
        resolve()
      }).catch((e: { response: { status: number } }) => {
        if (e.response.status === 403) {
          notification.warning({
            message: 'U heeft niet de juiste rechten!',
            description: 'U heeft niet de juiste rechten om deze keuring te bekijken.',
            duration: 5
          })
        } else {
          notification.error({
            message: 'Fout tijdens het ophalen van deze dag!',
            description: 'Er is iets mis gegaan. Probeer het later nog een keer.'
          })
        }
        router.push({ name: 'Diaries' })
        reject(e)
      }).finally(() => {
        loading.value.item = false
      })
    })
  }

  function updateDiaryDay (diaryDay:DiaryDay) : void {
    axios.put(process.env.VUE_APP_API_URL + '/diaries/days/' + diaryDay.id, diaryDay).then((r) => {
      if (diary.value) diary.value.schedule = r.data.data.schedule
      message.success('Dag bijgewerkt.')
    }).catch(() => {
      message.error('Er is iets mis gegaan. Herlaad de pagina en pas de gegevens opnieuw aan.', 0)
    })
  }

  function updateDiaryRegistration (diaryRegistration:DiaryRegistration) : void {
    axios.put(process.env.VUE_APP_API_URL + '/diaries/registrations/' + diaryRegistration.id, diaryRegistration).then(() => {
      message.success('Aanwezigheid bijgewerkt.')
    }).catch(() => {
      message.error('Er is iets mis gegaan. Herlaad de pagina en pas de gegevens opnieuw aan.', 0)
    })
  }

  return { diaries, diary, loading, errors, getDiaries, getDiary, updateDiaryDay, updateDiaryRegistration }
})
